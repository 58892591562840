<template>
  <v-dialog
    v-model="createRoundModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar Rodada
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="createRound()"
        >
          <v-text-field
            v-model="round.number"
            :rules="[rules.required]"
            type="number"
            label="Número da rodada *"
            outlined
            required
          />

          <v-datetime-picker
            v-model="round.startsAt"
            :datePickerProps="datePickerProps"
            :textFieldProps="textFieldProps"
            :timePickerProps="timeOpts"
            clearText="limpar"
            label="Início da rodada *"
            date-format="dd/MM/yyyy"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>

          <v-datetime-picker
            v-model="round.endsAt"
            :datePickerProps="datePickerProps"
            :textFieldProps="textFieldProps"
            :timePickerProps="timeOpts"
            clearText="limpar"
            label="Término da rodada *"
            date-format="dd/MM/yyyy"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>

          <v-switch inset v-model="round.open" label="Rodada aberta" color="primary" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createRound()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      saving: false,
      valid: true,
      round: {
        number: '',
        startsAt: '',
        endsAt: '',
        open: false
      },
      datePickerProps: {
        'no-title': true,
        scrollable: true,
        locale: 'pt-br'
      },
      textFieldProps: {
        readonly: true,
        outlined: true
      },
      timeOpts: {
        format: '24hr'
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório.',
        biggerThanStart: value =>
          (!!value && moment(this.round.endsAt).diff(moment(this.round.startsAt), 'days') >= 1) || 'Informe uma data posterior à data de início.'
      }
    }
  },
  computed: {
    createRoundModal: {
      get () {
        return this.$store.state.createRoundModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createRoundModal',
          value: val
        })
      }
    }
  },
  watch: {
    createRoundModal (val) {
      if (!val) {
        this.round = {
          number: '',
          startsAt: '',
          endsAt: '',
          open: false
        }
      }
    }
  },
  methods: {
    createRound () {
      if (this.$refs.form.validate()) {
        if (!this.round.startsAt) {
          this.$toast.warning('Informe a data de início da rodada.')
          return
        }
        if (!this.round.endsAt) {
          this.$toast.warning('Informe a data de fim da rodada.')
          return
        }
        this.saving = true
        const championshipId = this.$store.state.selectedChampionship
        if (!championshipId) this.$router.go(-1)

        this.$http.post(`championships/${championshipId}/rounds`, this.round)
          .then((res) => {
            this.saving = false
            this.createRoundModal = false
            this.$emit('created')
            this.$toast.success('Cadastro realizado com sucesso.')
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    }
  }
}
</script>
